import {
    redirect,
    type LoaderFunctionArgs,
    type MetaFunction,
} from 'react-router';
import { useRouteError } from 'react-router';
import * as Sentry from '@sentry/react';
import { GeneralError } from '~/components/general-error.component';
import { paths } from '~/paths';
import { requireUserSession } from '~/session.server';

export async function loader({ request }: LoaderFunctionArgs) {
    await requireUserSession(request);
    return redirect(paths.subscriptions());
}

export function ErrorBoundary() {
    const error = useRouteError();
    Sentry.captureException(error);

    return <GeneralError />;
}

export const meta: MetaFunction = () => {
    return [
        { title: `ready2order - Self Service` },
        { name: 'description', content: '' },
    ];
};

export default function Index() {
    return <></>;
}
